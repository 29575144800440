//==============================
// Audio Player
//==============================

/*

if ($('.player').length > 0) {

// Desktop / Wavesurfer
var wavesurfer = WaveSurfer.create({
  container: '#waveform',
  waveColor: '#93897b',
  progressColor: '#333',
  responsive: true,
  height: 60,
  barWidth: 2,
  barGap: 2,
  barRadius: 3
});


// Play button from multi player
$('.music-item-button.play').click(function() {
  var thisItem = $(this);
  var audioUrl = thisItem.attr('data-audio');
  var audioTitle = $(this).parent().parent().parent().find('.music-post-title').text();

  $('.player').removeClass('hidden');

  $('.music-item-button.play').removeClass('hidden');
  thisItem.addClass('hidden');
  $('.music-item-button.pause').addClass('hidden');
  thisItem.next('.pause').removeClass('hidden');

  $('.player-control-button.play').addClass('hidden');
  $('.player-control-button.pause').removeClass('hidden');

  if (thisItem.hasClass('playing')) {
    wavesurfer.play();
  }
  else {
    $('.music-item-button.play').removeClass('playing');
    thisItem.addClass('playing');

    $('.player.multi .player-title').html('&nbsp;');
    $('.player-time-current').text('0:00');
    $('.player-time-duration').text('0:00');

    wavesurfer.load(audioUrl);

    wavesurfer.on('ready', function() {
      
      var duration = wavesurfer.getDuration();
      duration = duration.toFixed([0]);
      duration = Math.round(duration);
      duration = parseInt(duration);

      function secondsTimeSpanToHMS(s) {
        var h = Math.floor(s/3600); //Get whole hours
        s -= h*3600;
        var m = Math.floor(s/60); //Get remaining minutes
        s -= m*60;
        return (m < 10 ? m : m)+":"+(s < 10 ? '0'+s : s); //zero padding on minutes and seconds
      }

      wavesurfer.play();

      $('.player.multi .player-title').text(audioTitle);
      $('.player-time-duration').text(secondsTimeSpanToHMS(duration));
      
    });
  }

  wavesurfer.on('audioprocess', function() {
    var currentTime = wavesurfer.getCurrentTime();

    currentTime = currentTime.toFixed([0]);
    currentTime = Math.round(currentTime);
    currentTime = parseInt(currentTime);

    function secondsTimeSpanToHMS(s) {
      var h = Math.floor(s/3600); //Get whole hours
      s -= h*3600;
      var m = Math.floor(s/60); //Get remaining minutes
      s -= m*60;
      return (m < 10 ? m : m)+":"+(s < 10 ? '0'+s : s); //zero padding on minutes and seconds
    }

    $('.player-time-current').text(secondsTimeSpanToHMS(currentTime));

  });

  // For player button triggering
  // var thisParent = thisItem.parent();
  // $('.music-item-buttons').removeClass('playing');
  // thisParent.addClass('playing');

});


// Play button from single player
if ($('.player.single').length > 0) {
  var thisItem = $('.player.single');
  var audioUrl = thisItem.find('.play').attr('data-audio');
  wavesurfer.load(audioUrl);

  wavesurfer.on('ready', function() {
    
    var duration = wavesurfer.getDuration();
    duration = duration.toFixed([0]);
    duration = Math.round(duration);
    duration = parseInt(duration);

    function secondsTimeSpanToHMS(s) {
      var h = Math.floor(s/3600); //Get whole hours
      s -= h*3600;
      var m = Math.floor(s/60); //Get remaining minutes
      s -= m*60;
      return (m < 10 ? m : m)+":"+(s < 10 ? '0'+s : s); //zero padding on minutes and seconds
    }

    $('.player-time-duration').text(secondsTimeSpanToHMS(duration));
    
  });
}

$('.player-control-button.play').click(function() {
  var thisItem = $(this);
  var audioUrl = thisItem.attr('data-audio');

  $('.player-control-button.play').addClass('hidden');
  $('.player-control-button.pause').removeClass('hidden');
  
  wavesurfer.play();

  wavesurfer.on('audioprocess', function() {
    var currentTime = wavesurfer.getCurrentTime();

    currentTime = currentTime.toFixed([0]);
    currentTime = Math.round(currentTime);
    currentTime = parseInt(currentTime);

    function secondsTimeSpanToHMS(s) {
      var h = Math.floor(s/3600); //Get whole hours
      s -= h*3600;
      var m = Math.floor(s/60); //Get remaining minutes
      s -= m*60;
      return (m < 10 ? m : m)+":"+(s < 10 ? '0'+s : s); //zero padding on minutes and seconds
    }

    $('.player-time-current').text(secondsTimeSpanToHMS(currentTime));

  });

});


// Play / pause buttons
$('.music-item-button.pause').click(function() {
  var thisItem = $(this);
  thisItem.addClass('hidden');
  $('.music-item-button.play').removeClass('hidden');
  $('.player-control-button.play').removeClass('hidden');
  $('.player-control-button.pause').addClass('hidden');
  wavesurfer.pause();
});

$('.player-control-button.play').click(function() {
  $('.player-control-button.play').addClass('hidden');
  $('.player-control-button.pause').removeClass('hidden');
  $('.music-item-button.play.playing').addClass('hidden');
  $('.music-item-button.play.playing').next('.pause').removeClass('hidden');
  wavesurfer.play();
});

$('.player-control-button.pause').click(function() {
  $('.player-control-button.play').removeClass('hidden');
  $('.player-control-button.pause').addClass('hidden');
  $('.music-item-button.play.playing').removeClass('hidden');
  $('.music-item-button.play.playing').next('.pause').addClass('hidden');
  wavesurfer.pause();
});


// Mutil player - Mobile / iOS Fallback / HTML
$('.music-item-button.play-mobile').click(function() {
  var thisItem = $(this);
  var audioUrl = thisItem.attr('data-audio');
  var thisParent = $(this).parent().parent();

  $('.music-item-button.play-mobile').removeClass('hidden');
  thisItem.addClass('hidden');
  $('.music-item-button.pause-mobile').addClass('hidden');
  thisItem.next('.pause-mobile').removeClass('hidden');

  if (thisItem.hasClass('playing')) {
    var thisAudio = thisParent.find('audio')[0];
    thisAudio.play();
  }
  else {
    $('.music-item-button.play-mobile').removeClass('playing');
    thisItem.addClass('playing');
    $('audio').remove();
    $('<audio>').attr('src',audioUrl).appendTo(thisParent);
    var thisAudio = thisParent.find('audio')[0];
    thisAudio.play();
  }
});

$('.music-item-button.pause-mobile').click(function() {
  var thisItem = $(this);
  var thisParent = $(this).parent().parent();
  thisItem.addClass('hidden');
  $('.music-item-button.play-mobile').removeClass('hidden');
  var thisAudio = thisParent.find('audio')[0];
  thisAudio.pause();
});

wavesurfer.on('seek', function() {
  wavesurfer.play();
});

// Add to playlist
// $('.js-player-button-playlist').click(function() {
//   var thisItem = $(this);
//   var playButton = $('.music-item-buttons.playing').find('.add_to_cart_button');
//   playButton.trigger('mouseup');
// });

}

*/


  

$('.music-control-button.play').click(function() {
  var thisItem = $(this);
  var thisUrl = thisItem.attr('data-audio');
  var audioTitle = thisItem.attr('data-title');
  var audioPlayer = $('#audio-player');
  var playerId = audioPlayer.attr('data-audio');

  if (thisUrl === playerId) {
    audioPlayer.trigger('play');

  }
  else {
    $('#audio-player').attr('src', thisUrl).attr('data-audio', thisUrl);
  }
  
  $('.music-control-button.play').removeClass('hidden');
  thisItem.addClass('hidden');
  $('.player').removeClass('hidden');
  $('.music-control-button.pause').addClass('hidden');
  thisItem.next().removeClass('hidden');

  $('.player .player-title').text(audioTitle);
});

$('.music-control-button.pause').click(function() {
  $('#audio-player').trigger('pause');
  $('.music-control-button.play').removeClass('hidden');
  $('.music-control-button.pause').addClass('hidden');
});

$('.player-close').click(function() {
  $('#audio-player').trigger('pause');
  $('.player').addClass('hidden');
  $('.music-control-button.play').removeClass('hidden');
  $('.music-control-button.pause').addClass('hidden');
});
