//----------------------------------------
// Add to cart
//----------------------------------------

$('.ajax_add_to_cart').addClass('music-item-button').attr('data-tooltip', 'Add to playlist').html('<i class="icofont-plus"></i>');

$('.add_to_cart_button').click(function() {
  var thisItem = $(this);
  var itemTitle = thisItem.attr('aria-label').replace('Add ', '').replace('to your cart', 'has been added to your playlist');

  // var addPlaylist;

  $('.added-to-playlist').removeClass('active');

  setTimeout(function() {
    $('.added-to-playlist').addClass('active');
    $('.added-to-playlist .added-to-playlist-text').text(itemTitle);
  }, 100);

});
