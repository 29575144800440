//==============================
// Global
//==============================

FastClick.attach(document.body);

$('.home-media, .video-gallery-item, .epk-video, .post-content p').fitVids();

$('.menu-item-type-custom a').attr('target', '_blank');



