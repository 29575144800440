//==============================
// Filtering
//==============================

$('.woocommerce-widget-layered-nav .widget-title').click(function() {
  var thisItem = $(this);
  var itemNav = thisItem.next();
  var itemContainer = thisItem.parent();

  if (thisItem.hasClass('open')) {
    thisItem.removeClass('open');
    itemNav.removeClass('open');
    itemContainer.removeClass('open');
  }
  else {
  // $('.woocommerce-widget-layered-nav .widget-title').removeClass('open');
    // $('.woocommerce-widget-layered-nav-list').removeClass('open');
    thisItem.addClass('open');
    itemNav.addClass('open');
    itemContainer.addClass('open');
  }
});



var urlFilter = window.location.search;

if (urlFilter.indexOf("sub-genre") >= 0) {
  $('.widget-title:contains("Sub-genre")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("mood") >= 0) {
  $('.widget-title:contains("Mood")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("vocal-type") >= 0) {
  $('.widget-title:contains("Vocal type")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("tempo") >= 0) {
  $('.widget-title:contains("Tempo")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("theme") >= 0) {
  $('.widget-title:contains("Theme")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("era") >= 0) {
  $('.widget-title:contains("Era")').addClass('open').next().addClass('open').parent().addClass('open');
}
if (urlFilter.indexOf("featured-instrument") >= 0) {
  $('.widget-title:contains("Featured instrument")').addClass('open').next().addClass('open').parent().addClass('open');
}



$('.music-utility-button-filter').click(function() {
  $('#sidebar').addClass('open');
});

$('.music-filter-close').click(function() {
  $('#sidebar').removeClass('open');
});


/*





function countResults() {
  var itemsCount = $('.music-item').length;
  var hiddenCount = $('.music-item:hidden').length;
  // console.log(itemsCount, hiddenCount);

  if (itemsCount === hiddenCount) {
    $('.music-noresults').addClass('show');
    // console.log('no results');
  }
  else {
    $('.music-noresults').removeClass('show');
    // console.log('some results');
  }
}

// search
$('#music-search').on('keyup', function() {
  var value = $(this).val().toLowerCase();
  $('.music-item').filter(function() {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
  });
  countResults();
});

// categories
$('.music-filter-button').click(function() {
  var thisItem = $(this);
  var cat = thisItem.attr('data-filter');

  console.log(cat);

  // $('.music-filter-button').removeClass('active');

  if ((thisItem).hasClass('active')) {
    $('.music-item').removeClass('hide');
    thisItem.removeClass('active');
  }
  else {
    $('.music-filter-button').removeClass('active');
    thisItem.addClass('active');
    $('.music-item').addClass('hide');
    $('.music-item[data-category*="' + cat + '"]').removeClass('hide');
  }

  countResults();
});

*/
