//==============================
// Music Modal
//==============================

$('.music-modal-link').click(function() {
  var thisItem = $(this);
  var thisModal = thisItem.next('.music-modal');
  var thisOverlay = thisModal.next('.music-modal-overlay');
  thisModal.addClass('open');
  thisOverlay.addClass('open');
  return false;
});

$('.music-modal-overlay, .music-modal-close').click(function() {
  $('.music-modal, .music-modal-overlay').removeClass('open');
});
